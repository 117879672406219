import React, { FC } from "react";

import arrowRight from "../assets/icons/arrow-right.svg";

interface Props {
  title: string;
  textColor: string;
}

const ButtonCrisp: FC<Props> = ({ title, textColor }) => {
  const handleClick: any = (event: MouseEvent) => {
    $crisp.push(['do', 'chat:open'])
  }

  return (
    <button
      className={`inline-flex w-fit items-center gap-4 rounded-sm bg-white py-4 px-6 font-semibold text-gray hover:bg-gray-200`}
      onClick={handleClick}
    >
      {title}
      <img src={arrowRight} className="w-4" alt="flèche vers la droite"></img>
    </button>
  );
};

export default ButtonCrisp;
