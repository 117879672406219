import * as React from "react";
import { Helmet } from 'react-helmet'
import { Fade } from 'react-reveal'

import Navbar from '../components/Navbar'
import ButtonCrisp from '../components/ButtonCrisp'
import Footer from '../components/Footer'

const ContactPage = () => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <main className="bg-[url('../assets/images/background-3.webp')] bg-cover bg-no-repeat">
      <Helmet htmlAttributes={{ lang: 'fr-FR' }}>
        <title>
          Contact | Ksatra SEM - Fullstack designer freelance à Toulouse
        </title>
        <meta
          name="description"
          content="Une idée de projet ? Échangeons ensemble."
        />
      </Helmet>

      {!isLoading && <Navbar bgColor="bg-black" textColor="text-white" />}

      <div className="px-6 pt-8 text-white lg:px-32 lg:pt-24">
        <Fade delay={800}>
          <h3 className="pb-6 text-lg">Contact.</h3>
        </Fade>
        <Fade bottom>
          <h1 className="mb-16 font-serif text-4xl font-bold leading-tight text-gray-200 lg:text-6xl">
            Une idée de projet ? <br /> Échangeons ensemble
          </h1>
        </Fade>

        <Fade bottom>
          <p className="my-16 text-xl text-gray-200 lg:w-2/3">
            Présentez votre projet et laissez-moi vos informations de contact.
            Je reviendrais vers vous dans un délai de 24h.
          </p>

          <div className="flex flex-col gap-8">
            <h2 className="font-serif text-2xl lg:text-3xl">
              contact@ksatra.fr
            </h2>{' '}
            <ButtonCrisp
              title="Contactez-moi par chat"
              textColor="text-white"
            />
          </div>
        </Fade>
      </div>

      <Fade bottom>
        <Footer />
      </Fade>
    </main>
  )
}

export default ContactPage;
